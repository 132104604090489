module.exports = [{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-starwars/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/clone.jpeg"},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-starwars/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-starwars/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
